import { SimpleEventDispatcher } from "strongly-typed-events";

import { PWSMemberService } from "../../services/pws";
import { PwsGiftRecipientResponse } from "../../services/pws/types";
import { parsePWSError, PremiereWebServiceError } from "../../services/pws/util";
import { SessionStateError } from "../backend";
import { Deferred } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import type { Store } from "../../stores";
import { SessionBackend } from "../../decoders/ihr-user";

const log = ILog.logger("PWS Session");

interface CoastPopUpMessageLink {
  target?: string;
  text: string;
  url: string;
}

interface CoastPopUpMessageButton {
  click: () => void;
  text: string;
}

export interface CoastPopUpMessage {
  button?: CoastPopUpMessageButton;
  link?: CoastPopUpMessageLink;
  text?: string;
  title: string;
}

export interface PopupState {
  view: string | null;
  data?: {
    message?: CoastPopUpMessage;
    passwordToken?: string;
    emailToken?: string;
    subMenu?: string;
  };
}

export const isPWSSessionBackend = (v: any): v is PWSSessionBackend => v && v instanceof PWSSessionBackend;

export interface PWSSessionState {
  hasActiveSubscription: boolean;
  profile: any;
  refreshToken: string | null;
  accessToken: string | null;
  expiresAt: number | null;
  createdAt: number | null;
  authenticated: boolean;
  giftRecipient?: PwsGiftRecipientResponse | null;
}

const PWS_DEFAULT_SESSION: PWSSessionState = {
  hasActiveSubscription: false,
  authenticated: false,
  refreshToken: null,
  accessToken: null,
  expiresAt: null,
  createdAt: null,
  profile: null,
};

export class TokenMissingError extends SessionStateError {
  constructor() {
    super("Missing access token");
    this.name = "TokenMissingError";
  }
}

export class TokenCreationDateError extends SessionStateError {
  constructor() {
    super("Token is missing creation date");
    this.name = "TokenCreationDateError";
  }
}

export class TokenExpiryDateError extends SessionStateError {
  constructor() {
    super("Token is missing expiration date");
    this.name = "TokenExpiryDateError";
  }
}

export class TokenExpiredError extends SessionStateError {
  constructor(expiryTime: number) {
    super(`Token has expired: ${expiryTime}`);
    this.name = "TokenExpiredError";
  }
}

export class PWSSessionBackend implements SessionBackend<PWSSessionState> {
  private _onUsersPopupChanged = new SimpleEventDispatcher<PopupState>();
  private _onStatusChanged = new SimpleEventDispatcher<PWSSessionState>();
  private staleTokenAge: number;

  readonly name = "C2CSessionV2";

  userModalState: { view: string; data?: PopupState["data"] } | null = null;
  pendingLoginPromise: Deferred<PWSSessionState> | null = null;
  memberService: PWSMemberService;
  state = PWS_DEFAULT_SESSION;
  validated = false;

  constructor(store: Store) {
    const { env } = store;
    this.memberService = new PWSMemberService(env.DEP_PWS_HOST, store.site.index.slug);
    this.staleTokenAge = Number(env.PWS_STALE_TOKEN_AGE || 86400000 * 3); // refresh if less than 3 days left

    this.getStateFromLocal();

    setInterval(
      () =>
        this.refresh()
          .then(() => log.debug("Session is refreshed"))
          .catch((e: SessionStateError | PremiereWebServiceError) => log.error(e)),
      Number(env.PWS_TOKEN_VALIDATION_INTERVAL || 3600000),
    );
  }

  get onStatusChanged() {
    return this._onStatusChanged.asEvent();
  }

  get authenticated() {
    return this.state.authenticated;
  }

  get onUserModalStageChanged() {
    return this._onUsersPopupChanged.asEvent();
  }

  get hasPrivacy() {
    return !!this.state.profile?.privacy_optout;
  }

  signup = () => Promise.resolve(this.state);

  onNavigate = () => {
    log.debug("Update Auth modal state on navigation");
    this.setModalStageFromQueryParams();
  };

  async refresh(sessionState = this.state) {
    if (!sessionState.accessToken) {
      throw new TokenMissingError();
    } else if (!sessionState.createdAt) {
      throw new TokenCreationDateError();
    } else if (!sessionState.expiresAt) {
      throw new TokenExpiryDateError();
    } else if (Date.now() >= sessionState.expiresAt) {
      throw new TokenExpiredError(sessionState.expiresAt);
    }

    try {
      const { tokenData, refreshToken, accessToken, user } = await this.memberService.validateToken(
        sessionState.accessToken,
        sessionState.refreshToken,
      );

      this.sessionUpdated({
        expiresAt: (tokenData.created_at + tokenData.expires_in_seconds) * 1000,
        createdAt: tokenData.created_at * 1000,
        authenticated: true,
        profile: user,
        // on token refresh will have these
        ...(refreshToken && { refreshToken }),
        ...(accessToken && { accessToken }),
      });

      return this.state;
    } catch (e) {
      // logger.error(e);
      throw new PremiereWebServiceError(parsePWSError(e));
    }
  }

  sessionUpdated(update: Partial<PWSSessionState>) {
    if (update.authenticated ?? this.state.authenticated) {
      try {
        this.persist({
          ...(this.state || {}),
          ...update,
          hasActiveSubscription:
            (update.profile?.has_active_subscription ?? this.state.profile?.has_active_subscription) || false,
        });
      } catch (e) {
        log.error(e);
        this.persist(PWS_DEFAULT_SESSION);
      }
    } else {
      this.persist(PWS_DEFAULT_SESSION);
    }

    this._onStatusChanged.dispatch(this.state);
    return this.state;
  }

  // this shows a popup and returns a promise that will be resolved or rejected based on user actions
  login() {
    this.pendingLoginPromise = new Deferred<PWSSessionState>();
    this.showUsersPopup();

    return this.pendingLoginPromise.promise;
  }

  logout = async () => {
    if (this.state.accessToken) {
      try {
        this.hideUsersPopup();
        await this.memberService.logout(this.state.accessToken);
      } catch (e) {
        log.error(e);
      }
    }

    return this.resetLocalSession();
  };

  // this fires off on user submit
  async doLogin(username: string, password: string) {
    try {
      const { user, loginData } = await this.memberService.login(username, password);

      this.sessionUpdated({
        expiresAt: (loginData.created_at + loginData.expires_in) * 1000,
        createdAt: loginData.created_at * 1000,
        refreshToken: loginData.refresh_token,
        accessToken: loginData.access_token,
        authenticated: !!loginData.access_token,
        profile: user,
      });

      if (this.pendingLoginPromise) {
        this.pendingLoginPromise.resolve(this.state);

        // needs to happen before hideUsersPopup()
        this.pendingLoginPromise = null;
      }
      return;
    } catch (e) {
      log.error("Error on login", e);
      return parsePWSError(e);
    }
  }

  isTokenStale(sess: PWSSessionState) {
    return !sess.createdAt || Date.now() - sess.createdAt > this.staleTokenAge;
  }

  showUsersPopup = (view = "login", data?: PopupState["data"]) => {
    this.userModalState = { view, data };
    this._onUsersPopupChanged.dispatch({ view, data });
  };

  hideUsersPopup = () => {
    this.userModalState = null;
    this._onUsersPopupChanged.dispatch({ view: null });

    this.pendingLoginPromise?.reject();
  };

  showPopUpMessage(message: CoastPopUpMessage) {
    this.showUsersPopup("message", { message });
  }

  executeOnValidSubscription = async (cb: () => void, onLogin?: () => void) => {
    try {
      // make sure the user hasn't logged out in a different window - ref: IHRAL-8320
      await this.refresh();
    } catch (error) {
      if (error instanceof SessionStateError) {
        log.debug(error.message);
      } else {
        log.error(error.message);
      }
    }

    if (!this.state.authenticated) {
      try {
        log.debug("Initiated login");

        await this.login();

        log.debug("Logged in successfully");

        // has a valid subscription and a separate callback for onLogin
        if (this.state.hasActiveSubscription && onLogin) {
          log.debug("Has valid subscription - custom onLogin callback");
          return onLogin();
        }
      } catch {
        /* do nothing */
        return;
      }
    }

    if (!this.state.hasActiveSubscription) {
      log.warn("Missing subscription");
      this.showMissingSubscriptionPopup();
    } else {
      cb();
    }
  };

  get giftRecipient(): PwsGiftRecipientResponse | null | undefined {
    return this.state.giftRecipient;
  }

  set giftRecipient(data: PwsGiftRecipientResponse | null | undefined) {
    this.state.giftRecipient = data;
    this.persist(this.state);
  }

  showMissingSubscriptionPopup = () => {
    this.showPopUpMessage({
      title: "You don't have a valid subscription",
      text: "Please subscribe to access Coast Insider content",
      button: {
        click: () => this.showUsersPopup("account"),
        text: "User Preferences",
      },
    });
  };

  private isStateDataValid(state: PWSSessionState) {
    if (state.authenticated && state.accessToken && state.refreshToken && state.createdAt && state.createdAt) {
      return true;
    }

    log.debug("State data is invalid: ", state);
    return false;
  }

  private local() {
    const localData = localStorage.getItem(this.name);

    if (localData) {
      try {
        const state = JSON.parse(localData);

        if (this.isStateDataValid(state)) {
          log.debug("Found local session data");
          return state;
        } else {
          log.warn("Invalid session data stored, removing it");
          localStorage.removeItem(this.name);
        }
      } catch (e) {
        log.debug("Invalid payload in local storage");
      }
    }

    return null;
  }

  private resetLocalSession() {
    try {
      return this.sessionUpdated(PWS_DEFAULT_SESSION);
    } catch (e) {
      log.error("Error when resetting local session:", e);
      return this.state;
    }
  }

  private setModalStageFromQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get("view");

    if (view) {
      const passwordToken = urlParams.get("passwordToken");
      const emailToken = urlParams.get("emailToken");
      const subMenu = urlParams.get("subMenu");
      this.showUsersPopup(view, {
        ...(!!emailToken && { emailToken }),
        ...(!!passwordToken && { passwordToken }),
        ...(!!subMenu && { subMenu }),
      });
    } else if (this.userModalState) {
      this.hideUsersPopup();
    }
  }

  private async getStateFromLocal() {
    const stored = this.local();

    if (stored) {
      // set first then validate
      this.state = stored;
      this._onStatusChanged.dispatch(this.state);

      try {
        if (stored.authenticated) {
          await this.refresh(stored);
        }

        this.validated = true;
        this._onStatusChanged.dispatch(this.state);
      } catch (e) {
        log.debug("Invalid stored token", e);
        this.validated = true;
        this.resetLocalSession();
      }
      this.setModalStageFromQueryParams();
    } else {
      this.validated = true;
      this._onStatusChanged.dispatch(this.state);
      this.setModalStageFromQueryParams();
    }
  }

  private persist(state: PWSSessionState) {
    this.state = state;
    localStorage.setItem(this.name, JSON.stringify(this.state));
  }
}
