import { IHRSessionState } from "../decoders/ihr-user";
import type { PWSSessionState } from "./backends/pws";

export function isIHRSession(v?: any | undefined | null): v is IHRSessionState {
  return !!(
    v &&
    v.hasOwnProperty("profile") &&
    v.profile &&
    v.profile.hasOwnProperty("accountType") &&
    v.profile.accountType
  );
}

export function isPWSSession(v?: any | undefined | null): v is PWSSessionState {
  return !!(
    v &&
    v.hasOwnProperty("profile") &&
    v.profile &&
    v.profile.hasOwnProperty("rss_token") &&
    v.profile.rss_token
  );
}

export class SessionStateError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "SessionStateError";
  }
}
